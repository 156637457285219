@font-face {
  font-family: 'Pretendard-Regular';
  src: url('https://fastly.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}


body {
  padding: 0;
  margin: 0;
  font-family: 'Pretendard-Regular', sans-serif;
}

/* slick-carousel 스타일 */
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";